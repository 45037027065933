import { render, staticRenderFns } from "./market-rules.vue?vue&type=template&id=04577071"
import script from "./market-rules.vue?vue&type=script&lang=js"
export * from "./market-rules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/market-rules-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fmarket-rules.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/market-rules-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fmarket-rules.vue&locale=ar&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default})
